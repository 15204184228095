<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: null,

      plans: null,
      plan: null,

      buy: {
        modal: false,
        loading: false,
        id: '',
        name: '',
        description: '',
        value: '',
      },
    };
  },
  methods: {
    getPlans() {
      this.plans = null;

      api
        .get('plans')
        .then((response) => {
          if (response.data.status == 'success') {
            this.plans = response.data.list;
          }
        })
    },
    getPlan() {
      this.plan = null;
      this.statData = null;

      api
        .get('plans/current')
        .then((response) => {
          if (response.data.status == 'success') {
            this.plan = response.data.plan

            if (this.plan.status == 'active') {
              this.statData = [
                {
                  title: "Plano Atual",
                  value: this.plan.name,
                },
                {
                  title: "Status",
                  value: this.plan.status,
                }
              ]
            } else {
              this.statData = [
                {
                  title: "Plano Atual",
                  value: this.plan.name,
                },
                {
                  title: "Status",
                  value: this.plan.status,
                },
              ]
            }
          } else {
            this.statData = [
              {
                title: "Plano Atual",
                value: "Cliente",
              },
              {
                title: "Status",
                value: "inactive",
              },
            ];
          }
        })
    },
    showBuy(plan) {
      this.buy.modal = false;
      this.buy.modal = true;
      this.buy.id = plan.id;
      this.buy.name = plan.name;
      this.buy.description = plan.description;
      this.buy.value = plan.value;
    },
    buyPlan() {
      if (this.buy.id) {
        this.buy.loading = true;

        api
          .post("plans", {
            id: this.buy.id
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$router.push('/store/order/' + response.data.order.id);
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.buy.modal = false;
              this.buy.loading = false;
            }
          })
          .finally(() => {
            this.buy.modal = false;
            this.buy.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getPlans()
    this.getPlan()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Meu Plano') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3 col-lg-3 col-xl-3">
        <Stat :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!plan && !plans" class="text-center">
      <b-spinner class="ml-2 align-middle" variant="default" role="status"></b-spinner>
    </div>
    <div v-else-if="!plan && plans">
      <div class="row">
        <div class="col-lg-4" v-for="(plan, index) in plans" :key="index">
          <div class="card">
            <div class="card-body">
              <img class="w-100 rounded mb-3" src="@/assets/images/plans/kit-light.jpg" />
              <h4 class="mb-1">{{ plan.name }}</h4>
              <h2 class="mb-1 text-default">{{ plan.description }}</h2>
              <h5 class="mb-4 text-dark">{{ plan.value | currency }}</h5>
              <button class="btn btn-default btn-block text-uppercase" v-on:click="showBuy(plan)">
                Escolher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="buy.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Confirmar compra</h4>
        <p>Confirma a compra do plano <strong>{{ buy.name }}</strong>?</p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-default mr-3" v-on:click="buyPlan()" :disabled="this.buy.loading">
            Confirmar
            <b-spinner v-if="buy.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <button class="btn btn-outline-link" v-on:click="buy.modal = false">Fechar <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>